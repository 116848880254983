<template>
  <div class="yellow_bg">

    <div v-if="$lang === 'hr'" class="container pt-5 pb-5">
      <div>
        <h3 class="text-center mb-5">Politika sprečavanja pranja novca društva Terralogix Group Limited (TX)</h3>
      </div>
      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">1</div>
          <div class="side-text">Izjava o Politici</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.1</div>
          <div class="side-text">
            <span>
              Ovo je politika društva Terralogix Group Limited (koje uključuje sva povezana društva u
              bilo kojoj jurisdikciji) (TX) za vođenje poslovanja na pošten i etičan način. TX se zalaže za
              profesionalno i pošteno djelovanje s integritetom u svom radu, poslovanju i poslovnim
              odnosima diljem svijeta te za primjenu i provođenje učinkovitih sustava koji potvrđuju
              njegovu nultu toleranciju i važnost sprečavanja pranja novca.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.2</div>
          <div class="side-text">
            <span>
              TX snažno poštuje sve zakone koji su relevantni za sprečavanje pranja novca u svim
              jurisdikcijama u kojima posluje. TX namjerava poštivati sve zakone koji se odnose na
              sprečavanje pranja novca i sve relevantne lokalne propise za sprečavanje pranja novca.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.3</div>
          <div class="side-text">
            <span>
Ova Politika pruža informacije i smjernice o tome kako prepoznati i rješavati probleme s
pranjem novca.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.4</div>
          <div class="side-text">
            <span>
Pranje novca se općenito koristi za opis aktivnosti organiziranih kriminalaca koji prihod
od kriminala pretvaraju u zakonite aktivnosti, skrivajući tako svoje prave izvore.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">2</div>
          <div class="side-text">Primjenjivost ove Politike</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.1</div>
          <div class="side-text">
            <span>
Ova se Politika primjenjuje na sve pojedince koji rade na svim razinama unutar TX-a (TX
tim).
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.2</div>
          <div class="side-text">
            <span>
Od svih se poslovnih partnera TX-a traži da slijede ista načela i obaveze u vezi
sprečavanja pranja novca te da se tijekom poslovanja s TX-om pridržavaju odredbi ove
Politike.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">3</div>
          <div class="side-text">Odgovornost ove Politike</div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.1</div>
          <div class="side-text">
            Upravljački tim ima ukupnu odgovornost za osiguranje da ova Politika poštuje zakonske
            i etičke obaveze TX-a, kao i da je poštuju svi koji su pod kontrolom TX-a.
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.2</div>
          <div class="side-text">
            Glavnom je savjetniku dodijeljena odgovornost za savjetovanje TX tima o pitanjima
            sprečavanja pranja novca. Glavni savjetnik je odgovoran za procjene rizika, uključujući
            procjenu agenata, konzultanata i ostalih poslovnih partnera.
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">3.3</div>
          <div class="side-text">
            Glavni savjetnik ima primarnu i svakodnevnu odgovornost za provođenje ove Politike i
            praćenje njezina korištenja i učinkovitosti te rješavanje svih upita u vezi tumačenja iste.
            Rukovodstvo je na svim razinama odgovorno za osiguranje da su pojedinci koji ih
            izvještavaju upoznati te da razumiju ovu Politiku kao i da im se omogući odgovarajuća i
            redovita obuka. Adresa za kontakt glavnog savjetnika je: GC@terralogix.ai.
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">3.4</div>
          <div class="side-text">
            Imate li bilo kakvih pitanja ili upita u vezi ove Politike, ili ako sumnjate u odgovarajuće
            postupke navedene u ovoj Politici, molimo vas da se obratite glavnom savjetniku.
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">4</div>
          <div class="side-text">
            <span>
Odgovornost i zaštita
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.1</div>
          <div class="side-text">
            <span>
Svakako trebate pročitati, razumjeti i pridržavati se ove Politike.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.2</div>
          <div class="side-text">
            <span>
Sprečavanje, otkrivanje i izvještavanje o pranju novca i drugim oblicima istog
odgovornosti su onih koji rade za ili s društvom TX. TX tim i poslovni partneri moraju
izbjegavati bilo kakve aktivnosti koje bi mogle uzrokovati ili sugerirati kršenje ove
Politike.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.3</div>
          <div class="side-text">
            <span>
Svaka transakcija, bez obzira koliko djelovala beznačajna, a koja bi mogla dovesti do
kršenja ove Politike i/ili bilo kojeg važećeg Zakona o sprečavanju pranja novca, mora se
odmah prijaviti glavnom savjetniku.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.4</div>
          <div class="side-text">
            <span>
Svaki član TX tima koji prekrši ovu Politiku suočit će se s disciplinskom mjerom koja
može rezultirati otkazom zbog teške povrede dužnosti. Zadržavamo pravo raskinuti
ugovorni odnos s članom TX tima ili poslovnim partnerima ako prekrše ovu Politiku.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.5</div>
          <div class="side-text">
            <span>
TX nastoji potaknuti otvorenost i pruža podršku svima koji temeljem ove Politike izraze
iskrenu zabrinutost u dobroj vjeri, čak i ako se ispostavi da griješe.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.6</div>
          <div class="side-text">
            <span>
TX je posvećen tome da osigura da nitko ne trpu štetno postupanje kao rezultat
dobronamjerne prijave o sumnji temeljem ove Politike. Ako vjerujete da ste pretrpjeli
takav tretman, odmah obavijestite glavnog savjetnika.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">5</div>
          <div class="side-text">
            <span>
              Pokazatelji za sumnju na aktivnosti pranja novca
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.1</div>
          <div class="side-text">
            <span>
Nije moguće dati iscrpan popis kako prepoznati pranje novca. Međutim, slijede primjeri
mogućih pokazatelja za sumnju na aktivnost pranja novca (Upozorenja):
            </span>

            <div class="p-cont d-flex">
              <div class="side-num">a)</div>
              <div class="side-text">
                <span>
transakcije koje nemaju očitu svrhu i koje nemaju očigledan ekonomski smisao;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">b)</div>
              <div class="side-text">
                <span>
transakcije koje se traže bez razumnog objašnjenja, izvan uobičajenog spektra
usluga koje se obično traže ili su izvan iskustva društva TX u odnosu na
određenog klijenta/poslovnog partnera;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">c)</div>
              <div class="side-text">
                <span>
odbijanje davanja traženih informacija bez razumnog objašnjenja;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">d)</div>
              <div class="side-text">
                <span>
neobične investicijske transakcije bez očitog i uočljivog profitabilnog motiva;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">e)</div>
              <div class="side-text">
                <span>
odbijanje klijenta/poslovnog partnera da izvrši transakciju kada se od njega traži
identifikacija;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">f)</div>
              <div class="side-text">
                <span>
ekstenzivna uporaba offshore računa, tvrtki ili struktura u okolnostima u kojima
potrebe klijenta/poslovnog partnera ne podržavaju takve zahtjeve;
                </span>
              </div>
            </div>
            <div class="p-cont d-flex">
              <div class="side-num">g)</div>
              <div class="side-text">
                <span>
veličina ili obrazac transakcija ne podudara se s nijednim prethodno nastalim
uzorkom, bez razumnog objašnjenja;
                </span>
              </div>
            </div>
            <div class="p-cont d-flex">
              <div class="side-num">h)</div>
              <div class="side-text">
                <span>
plaćanja u gotovini koja nude poslovni partneri/klijenti;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">i)</div>
              <div class="side-text">
                <span>
klijent/poslovni partner nije fizički prisutan u relevantnoj zemlji ili kada se posao
treba obaviti u zemlji s lošim ugledom;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">j)</div>
              <div class="side-text">
                <span>
poslovne aktivnosti koje su u suprotnosti s temeljnim vrijednostima brenda;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">k)</div>
              <div class="side-text">
                <span>
sumnjivo bliske veze s vladinim dužnosnicima, prijašnje optužbe za korupciju ili
neetičko ponašanje ili nedostatak proporcionalnosti između predloženog rada i
naknada; ili
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">l)</div>
              <div class="side-text">
                <span>
druge aktivnosti koje mogu dovesti do kršenja ove Politike.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.2</div>
          <div class="side-text">
            <span>
TX tim i poslovni partneri trebaju biti svjesni Upozorenja koja mogu značiti da se odvija
pranje novca ili koja daju osnove za sumnju.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.3</div>
          <div class="side-text">
            <span>
Lista upozorenja nije iscrpna, a TX tim i poslovni partneri trebaju koristiti zdrav razum
pri utvrđivanju mogućih razloga da TX ne posluje s potencijalnim partnerom za pružanje
relevantnih usluga ili sklapanje bilo kakvih ugovora s potencijalnim partnerom.
            </span>
          </div>
        </div>

      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">6</div>
          <div class="side-text fw-600">
            <span>
              Postupci identifikacije
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">6.1</div>
          <div class="side-text">
            <span>
TX ima za cilj primijeniti, koliko je to moguće, postupke za sprečavanje pranja novca.
Okvir za to naveden je u nastavku.
            </span>

            <div class="p-cont d-flex">
              <div class="side-num">a)</div>
              <div class="side-text">
                <span>
TX će imenovati samo posrednike (uključujući prodajne agente, predstavnike i
druge slične savjetnike) i surađivati s poslovnim partnerima koji u svakom
trenutku pokazuju poslovni integritet i koji prakticiraju etičko ponašanje u skladu
sa standardima koje očekuju TX i svi primjenjivi zakoni i propisi.
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">b)</div>
              <div class="side-text">
                <span>
Imenovanje posrednika i angažiranje poslovnih partnera podliježe odobrenju
glavnog savjetnika ili Službenika za usklađenost.
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">c)</div>
              <div class="side-text">
                <span>
Prije sklapanja bilo kakvog ugovora ili poslovnog odnosa s bilo kojim posrednikom
ili poslovnim partnerima, službenik ili zaposlenik odgovoran za imenovanje
moraju prvo obavijestiti glavnog savjetnika ili Službenika za usklađenost
(uključujući bilo kakvu sumnju temeljem ove Politike) pisanim putem (e-mail je
dovoljan). Ako glavni savjetnik ili Službenik za usklađenost ocijeni da je to
potrebno, može se zatražiti da službenik ili zaposlenik za imenovanje ispuni i
preda kontrolnu listu dubinske analize, osobito ondje gdje je vjerojatno da
stranke razmjenjuju novac u sklopu neke transakcije ili partnerstva, a da nije riječ
o redovnoj jednostavnoj prodaji robe ili pružanju usluga.
                </span>
              </div>
            </div>

          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">6.2</div>
          <div class="side-text">
            <span>
Glavni će savjetnik tada razmotriti ponudu i obaviti dubinsku analizu koja se bude
smatrala potrebnom prije potvrde odobrenja imenovanja. Dubinsku analizu će obaviti
glavni savjetnik ili će se ista obaviti na zahtjev glavnog savjetnika koji može zatražiti
usluge treće strane na raspolaganju TX-u kako bi se pomoglo pri dubinskoj analizi.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">6.3</div>
          <div class="side-text">
            <span>
Upravljački tim će donositi odluke da se ne angažira neka tvrtka ili da se prekine
poslovanje s istom.
            </span>
          </div>
        </div>


      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">7</div>
          <div class="side-text">
            <span class="fw-600">
              Izražavanje zabrinutosti
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">7.1</div>
          <div class="side-text">
            <span>
Potiče vas se da izrazite zabrinutost o bilo kojem pitanju ili sumnji u bilo kojoj ranoj fazi.
Ako niste sigurni predstavlja li određeni čin pranje novca, ili u vezi bilo kojih drugih
pitanja, o tome se trebate posavjetovati s glavnim savjetnikom.
            </span>
          </div>
        </div>


        <div class="p-cont d-flex">
          <div class="side-num">7.2</div>
          <div class="side-text">
            <span>
Osobe koje žele ostati anonimne mogu to napraviti kreiranjem privremene adrese e-
pošte (usluga e-pošte koja nije specifična za jurisdikciju) i slanjem e-maila s privremene
adrese na adresu: GC@terralogix.ai. SAMO će glavni savjetnik dobiti taj e-mail te će
odmah poduzeti korake kako bi samostalno i povjerljivo istražio prijavu bez traženja
pomoći od pošiljatelja.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">7.3</div>
          <div class="side-text">
            <span>
Ako se stvar smatra dovoljno ozbiljnom, glavni će savjetnik razmotriti odgovarajuće
daljnje radnje, uključujući obavještavanje izvršnog direktora i glavnog financijskog
direktora, vanjskog pravnog savjetnika i/ili relevantnih tijela treće strane.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">7.4</div>
          <div class="side-text">
            <span>
Poduzet će se svi odgovarajući koraci za uvažavanje i zaštitu anonimnosti bilo koje
osobe koja izvrši takvu prijavu.
            </span>
          </div>
        </div>

      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">8</div>
          <div class="side-text">
            <span class="fw-600">
              Praćenje i preispitivanje
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.1</div>
          <div class="side-text">
            <span>
Upravljački tim i glavni savjetnik ili Službenik za usklađenost izravno su odgovorni za
nadzor programa sprečavanja pranja novca. Glavni savjetnik ili Službenik za usklađenost
trebaju imati odgovarajuću ovlast i dovoljno resursa za provođenje i praćenje svih
aktivnosti programa; on/a će biti odgovoran za osiguranje da se TX tim redovito
obavještava o razvoju zakona i praksi sprečavanja pranja novca, ako se to smatra
prikladnim.
            </span>
          </div>
        </div>


        <div class="p-cont d-flex">
          <div class="side-num">8.2</div>
          <div class="side-text">
            <span>
Upravljački tim i glavni savjetnik ili Službenik za usklađenost odgovorni su za osiguranje
uspostave odgovarajućih sustava i kontrola na lokalnoj razini i učinkovitog provođenja
ove Politike.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.3</div>
          <div class="side-text">
            <span>
Ako se čini da neki aspekt ove Politike nije prikladan za osiguranje lokalne usklađenosti
s relevantnim zakonom o sprečavanju pranja novca, isti će se odmah prenijeti na
Upravljački tim s ciljem da se istakne potreba za promjenama ili poboljšanjem ove
Politike.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.4</div>
          <div class="side-text">
            <span>
Glavni savjetnik ili Službenik za usklađenost je odgovoran za osiguranje da je procjena
lokalne usklađenosti s ovom Politikom uključena u opis poslova unutarnje ili bilo koje
vanjske revizije, prema potrebi. Osim toga, glavni savjetnik će biti odgovoran za
dogovor o programu i obuci o praćenju usklađenosti te zahtjevima izvještavanja koji se
trebaju provoditi na lokalnoj razini (u skladu s minimalnim standardima koje ova Politika
postavlja na globalnoj osnovi).
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.5</div>
          <div class="side-text">
            <span>
TX tim i poslovni partneri su odgovorni za uspjeh ove Politike i trebaju osigurati da se
otkrije svaka sumnja na opasnost ili prijestup.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.6</div>
          <div class="side-text">
            <span>
TX tim se poziva da komentira ovu Politiku i predloži načine kako je poboljšati.
Komentare, prijedloge i upute potrebno je uputiti glavnom savjetniku.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.7</div>
          <div class="side-text">
            <span>
Ova Politika nije ugovora o radu i može se u bilo kojem trenutku promijeniti.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.8</div>
          <div class="side-text">
            <span>
Glavni savjetnik će svake godine izvještavati Upravljački tim.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.9</div>
          <div class="side-text">
            <span>
Informacije o unutarnjem izvještavanju i upravljanju omogućit će TX-u prikupljanje
povratnih informacija o učinkovitosti ove Politike i njezinoj provedbi te osigurati da
prikladnost i učinkovitost iste ostanu optimalni.
            </span>
          </div>
        </div>

      </div>


      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">9</div>
          <div class="side-text">
            <span class="fw-600">
              Izvještavanje
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">9.1</div>
          <div class="side-text">
            <span>
Prijave izvršene temeljem ovog postupka istražit će glavni savjetnik ili Službenik za
usklađenost u prvom stupnju, a kasnije uključivanjem odgovarajućih osoba u bilo kojem
drugom relevantnom subjektu.
            </span>
          </div>
        </div>


        <div class="p-cont d-flex">
          <div class="side-num">9.2</div>
          <div class="side-text">
            <span>
Osobe koje primaju prijave koje se podnesu temeljem ovog dijela ove Politike razmotrit
će je li potrebno vanjsko izvještavanje u relevantnoj jurisdikciji te, prema potrebi,
izvještavanje lokalnog službenika za izvještavanje o pranju novca koji će razmotriti
sastavljanje izvješća temeljem lokalnog zakonodavstva o sprečavanju pranja novca ako
se čini da postoji kriminalno ponašanje za koje bi bilo potrebno takvo izvješće.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">9.3</div>
          <div class="side-text">
            <span>
Potrebno izvještavanje ili dobrovoljno samo-izvještavanje relevantnim tužiteljskim i
drugim tijelima odvijat će se u skladu s lokalnim zahtjevima koji se primjenjuju na svako
društvo ili organizaciju obuhvaćenu ovom Politikom.
            </span>
          </div>
        </div>

      </div>
    </div>

    <div v-else class="container pt-5 pb-5">
      <div>
        <h3 class="text-center mb-5">Terralogix Group Limited (TX) Anti Money Laundering Policy</h3>
      </div>
      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">1</div>
          <div class="side-text">Policy Statement</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.1</div>
          <div class="side-text">
            <span>
              It is the policy of Terralogix Group Limited (which includes all subsidiaries in any jurisdiction) (TX) to conduct its business in an honest and ethical manner. TX is committed to acting professionally, fairly and with integrity in its operations, business dealings and relationships worldwide and in implementing and enforcing effective systems to acknowledge its zero-tolerance and the importance of preventing money laundering.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.2</div>
          <div class="side-text">
            <span>
              TX strongly upholds all laws relevant to preventing money laundering in all of the jurisdictions in which it operates. TX intends to comply with any laws and regulations related to preventing money laundering and all relevant local anti-money laundering regulations.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.3</div>
          <div class="side-text">
            <span>
This Policy provides information and guidance on how to recognise and deal with money laundering issues.
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">1.4</div>
          <div class="side-text">
            <span>
Money laundering is generally used to describe the activities of organised criminals converting the proceeds of crime into legitimate activities, thus hiding their true sources.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">2</div>
          <div class="side-text">Applicability of this Policy</div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.1</div>
          <div class="side-text">
            <span>
              This Policy applies to all individuals working at all levels within TX (the TX Team).
            </span>
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">2.2</div>
          <div class="side-text">
            <span>
              All business partners of TX are requested to follow the same principles and commitments regarding anti-money laundering and to adhere to the provisions of this Policy at all times while conducting business with TX.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">3</div>
          <div class="side-text">Responsibility for this Policy</div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.1</div>
          <div class="side-text">
            The Management Team has overall responsibility for ensuring that this Policy complies with TX legal and ethical obligations, and that everyone under control of TX complies with it.
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">3.2</div>
          <div class="side-text">
            The General Counsel has been given responsibility for advising the TX Team on anti-money laundering issues. The General Counsel is responsible for carrying out risk assessments, including the assessment of agents, consultants and other business partners.
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">3.3</div>
          <div class="side-text">
            The General Counsel has primary and day-to-day responsibility for implementing this Policy and for monitoring its use and effectiveness and dealing with any queries regarding its interpretation. Management at all levels are responsible for ensuring that the individuals reporting to them are made aware of and understand this Policy and are given adequate and regular training. The contact details for the General Counsel is GC@terralogix.ai.
          </div>
        </div>
        <div class="p-cont d-flex">
          <div class="side-num">3.4</div>
          <div class="side-text">
            Should you have any questions or queries in respect of this Policy, or if you are in any doubt as to the appropriate procedures set out under this Policy, please contact the General Counsel.
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">4</div>
          <div class="side-text">
            <span>
              Responsibilities and Protection
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.1</div>
          <div class="side-text">
            <span>
              You must ensure that you read, understand and comply with this Policy.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.2</div>
          <div class="side-text">
            <span>
              The prevention, detection and reporting of money laundering and its other forms are the responsibility of all those working for or with TX. The TX Team and business partners are required to avoid any activity that might lead to or suggest a breach of this Policy.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.3</div>
          <div class="side-text">
            <span>
              Any transaction, no matter how seemingly insignificant, that might give rise to a violation of this Policy and/or any applicable Anti-Money Laundering Laws must be reported immediately to the General Counsel.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.4</div>
          <div class="side-text">
            <span>
              Any member of the TX Team who breaches this Policy will face disciplinary action which could result in dismissal for gross misconduct. We reserve the right to terminate the contractual relationship with a member of the TX Team or business partners if they breach this Policy.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.5</div>
          <div class="side-text">
            <span>
              TX strives to encourage openness and will support anyone who raises genuine concerns in good faith under this Policy, even if they turn out to be mistaken.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">4.6</div>
          <div class="side-text">
            <span>
              TX is committed to ensuring that no one suffers any detrimental treatment as a result of their good faith reporting of their suspicion under this Policy. If you believe that you have suffered any such treatment, inform the General Counsel immediately.
            </span>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-cont d-flex fw-600">
          <div class="side-num">5</div>
          <div class="side-text">
            <span>
              Indicators for suspicion of money laundering activities
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.1</div>
          <div class="side-text">
            <span>
              It is not possible to provide an exhaustive list of the ways to identify money laundering. However, the following are examples of possible indicators of suspicion for money laundering activity (Red Flags):
            </span>

            <div class="p-cont d-flex">
              <div class="side-num">a)</div>
              <div class="side-text">
                <span>
                  transactions which have no apparent purpose, and which make no obvious economic sense;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">b)</div>
              <div class="side-text">
                <span>
                  transactions being requested without reasonable explanation, out of the ordinary range of services normally requested or is outside the experience of TX in relation to the particular client/business partner;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">c)</div>
              <div class="side-text">
                <span>
                  refusal to provide the information requested without reasonable explanation;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">d)</div>
              <div class="side-text">
                <span>
                  unusual investment transactions without an apparent and discernible profitable motive;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">e)</div>
              <div class="side-text">
                <span>
                  A client/business partner refusal to proceed with a transaction when asked for identification;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">f)</div>
              <div class="side-text">
                <span>
                  the extensive use of offshore accounts, companies or structures in circumstances where the client's/business partner's needs do not support such requirements;
                </span>
              </div>
            </div>
            <div class="p-cont d-flex">
              <div class="side-num">g)</div>
              <div class="side-text">
                <span>
                  the size or pattern of transactions is out of line with any pattern that has previously emerged, without reasonable explanation;
                </span>
              </div>
            </div>
            <div class="p-cont d-flex">
              <div class="side-num">h)</div>
              <div class="side-text">
                <span>
                  cash payment been offered by the business partners/clients;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">i)</div>
              <div class="side-text">
                <span>
                  the client/business partner has no physical presence in the relevant country or where business is to be transacted in a country with a poor reputation record;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">j)</div>
              <div class="side-text">
                <span>
                  business activities that contradict core brand values;
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">k)</div>
              <div class="side-text">
                <span>
                  suspiciously close ties to government officials, previous allegations of corruption or unethical behaviour or a lack of proportionality between the proposed work and fees; or
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">l)</div>
              <div class="side-text">
                <span>
                  other activity that might lead to a breach of this Policy.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.2</div>
          <div class="side-text">
            <span>
              TX Team and business partners should be aware of the Red Flags which may indicate that money laundering is taking place or providing grounds to raise suspicion.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">5.3</div>
          <div class="side-text">
            <span>
              The Red Flag list is not exhaustive, and TX Team and business partners should apply common sense in determining whether there may be other reasons for TX not to use a potential partner to provide the relevant services or enter into any contract with such a potential partner.
            </span>
          </div>
        </div>

      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">6</div>
          <div class="side-text fw-600">
            <span>
              Identification Procedures
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">6.1</div>
          <div class="side-text">
            <span>
              TX aims to implement, as far as practicable, procedures to prevent money laundering. The framework for doing so is set out below.
            </span>

            <div class="p-cont d-flex">
              <div class="side-num">a)</div>
              <div class="side-text">
                <span>
                  TX will only appoint intermediaries (including sales agents, introducers and other similar consultants) and engage with business partners who demonstrate business integrity at all times and who practice ethical conduct which meets the standards expected by TX and all applicable laws and regulations.
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">b)</div>
              <div class="side-text">
                <span>
                  The appointment of intermediaries and engagement of business partners is subject to the approval of the General Counsel or compliance officer.
                </span>
              </div>
            </div>

            <div class="p-cont d-flex">
              <div class="side-num">c)</div>
              <div class="side-text">
                <span>
                  Prior to entering into any contract or business relationship with any intermediary or business partners, the officer or employee responsible for the appointment must first notify General Counsel or compliance officer (including any suspicion under this Policy) in writing (email is sufficient). If General Counsel or compliance officer deems it necessary, the appointing officer or employee may be requested to complete and submit a due diligence checklist particularly where there are likely to be money follows between the parties as part of a transaction or partnership other than for the simple sale of goods or provision of services in the ordinary course.
                </span>
              </div>
            </div>

          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">6.2</div>
          <div class="side-text">
            <span>
              The General Counsel will then consider the proposal and carry out further due diligence as they consider necessary before confirming their approval of the appointment. Due diligence will be carried out by or at the request of the General Counsel who may retain third party services available to TX to assist with the due diligence.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">6.3</div>
          <div class="side-text">
            <span>
              Decisions not to engage in a business or to cease to do business will be taken by the Management Team.
            </span>
          </div>
        </div>


      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">7</div>
          <div class="side-text">
            <span class="fw-600">
              Raising a Concern
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">7.1</div>
          <div class="side-text">
            <span>
              You are encouraged to raise concerns about any issue or suspicion at the earliest possible stage. Any uncertainties as to whether a particular act constitutes money laundering, or any other queries, should be raised with the General Counsel.
            </span>
          </div>
        </div>


        <div class="p-cont d-flex">
          <div class="side-num">7.2</div>
          <div class="side-text">
            <span>
              Persons who wish to remain anonymous, by creating a temporary mail address (an email service that is non-jurisdiction specific) and sending an email from the temporary address to GC@terralogix.ai. The email will be received ONLY by the General Counsel, who will take immediate steps to investigate the report independently and confidentially, without recourse to the sender.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">7.3</div>
          <div class="side-text">
            <span>
              If the matter is considered of sufficient seriousness, the General Counsel will consider together appropriate further action, including informing the CEO and CFO, external legal counsel and/or relevant third party authorities.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">7.4</div>
          <div class="side-text">
            <span>
              All appropriate steps will be taken to respect and protect the anonymity of any person making such a report.
            </span>
          </div>
        </div>

      </div>

      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">8</div>
          <div class="side-text">
            <span class="fw-600">
              Monitoring and Review
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.1</div>
          <div class="side-text">
            <span>
              The Management Team and the General Counsel or Compliance Officer are directly accountable for the oversight of the anti-money laundering program. The General Counsel or Compliance Officer should have the proper authority and sufficient resources to implement and monitor all program activities; he/she will be responsible for ensuring that the TX Team are provided with regular briefings on developments in anti-money laundering law and practice, as deemed appropriate.
            </span>
          </div>
        </div>


        <div class="p-cont d-flex">
          <div class="side-num">8.2</div>
          <div class="side-text">
            <span>
              Management Team and the General Counsel or Compliance Officer is responsible for ensuring that appropriate systems and controls are put in place locally and that this Policy is effectively implemented.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.3</div>
          <div class="side-text">
            <span>
              If any aspect of this Policy appears to be inadequate to ensure local compliance with relevant anti-money laundering law, it will be escalated immediately to the Management Team with a view to highlighting the need for changes to or enhancement of this Policy.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.4</div>
          <div class="side-text">
            <span>
              The General Counsel or Compliance Officer is responsible for ensuring that the assessment of local compliance with this Policy is included in internal and any external audit terms of reference, as appropriate. In addition the General Counsel will be responsible for agreeing on the compliance monitoring program and training and reporting requirements which should be implemented locally (subject to the minimum standards set by this Policy on a global basis).
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.5</div>
          <div class="side-text">
            <span>
              The TX Team and business partners are responsible for the success of this Policy and should ensure they use it to disclose any suspected danger or wrongdoing.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.6</div>
          <div class="side-text">
            <span>
              The TX Team are invited to comment on this Policy and suggest ways in which it might be improved. Comments, suggestions and queries should be addressed to the General Counsel.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.7</div>
          <div class="side-text">
            <span>
              This Policy does not form part of any contract of employment and it may be amended at any time.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.8</div>
          <div class="side-text">
            <span>
              The General Counsel will report to the Management Team on an annual basis.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">8.9</div>
          <div class="side-text">
            <span>
              Internal reporting and management information will enable TX to gather feedback on the effectiveness of this Policy and its implementation and to ensure that its adequacy and effectiveness remain optimal.
            </span>
          </div>
        </div>

      </div>


      <div class="mb-5">
        <div class="p-cont d-flex">
          <div class="side-num fw-600">9</div>
          <div class="side-text">
            <span class="fw-600">
              Reporting
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">9.1</div>
          <div class="side-text">
            <span>
              Reports made under this procedure will be investigated by the General Counsel or Compliance Officer in the first instance, and later involving appropriate persons in any other relevant entity.
            </span>
          </div>
        </div>


        <div class="p-cont d-flex">
          <div class="side-num">9.2</div>
          <div class="side-text">
            <span>
              The persons receiving reports submitted under this part of this Policy will consider whether external reporting is required in the relevant jurisdiction and, where necessary, report to the local money laundering reporting officer who will consider compiling a report under local anti-money laundering legislation if it appears there is criminal conduct which would require such a report.
            </span>
          </div>
        </div>

        <div class="p-cont d-flex">
          <div class="side-num">9.3</div>
          <div class="side-text">
            <span>
              Required reporting or voluntary self-reporting to the relevant prosecuting and other bodies will take place in accordance with local requirements applicable to each company or organisation covered by this Policy.
            </span>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "Compliance"
};
</script>

<style scoped>
.p-cont {
  margin-bottom: 10px;
}

.side-num {
  width: 70px;
}

.side-text {
  width: calc(100% - 70px);
}
</style>
